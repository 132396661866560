import { useState, useRef, useEffect, useCallback } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import React from "react";

import HackerText, { hackerTextFunction } from "react-hacker-text";

const CarouselItem = ({
    text,
    pos,
    isLast,
    isFirst,
    shiftBy,
    activePos,
}: {
    text: string;
    pos: number;
    isLast: boolean;
    isFirst: boolean;
    shiftBy: (num: number) => void;
    activePos: number;
}) => {
    const TransitionDuration = isLast || isFirst ? 0 : 0.2;
    const animationControls = useAnimation();

    const sequence = async () => {
        await animationControls.start({
            strokeDasharray: 884.72,
            strokeDashoffset: 0,
            transition: { duration: 0.1 },
            opacity: 0.5,
        });

        await animationControls.start({
            strokeDasharray: 400,
            strokeDashoffset: 400,
            transition: { duration: 0.2 },
            opacity: 0.2,
        });
        animationControls.start({
            strokeDasharray: 884.72,
            strokeDashoffset: 0,
            transition: { duration: 0.1 },
            opacity: 1,
        });
    };
    if (pos === activePos) {
        sequence();
    }

    return (
        <motion.div
            className={
                "uppercase select-none absolute top-0 flex h-24 w-full items-center justify-center text-center font-bold font-mono cursor-pointer" +
                (pos === activePos
                    ? ""
                    : " border border-solid border-[#B7B7B7]")
            }
            animate={{
                top: pos * 110,
                color: pos === activePos ? "#A804EA" : "#B7B7B7",
                left: pos === activePos ? "" : "0",
            }}
            transition={{
                type: "tween",
                duration: TransitionDuration,
                color: { duration: 1.0 },
            }}
            onClick={async () => {
                console.log("pos = ", pos);
                console.log("activePos = ", activePos);
                shiftBy(activePos - pos);
                await sequence();
            }}
            layout
        >
            {pos === activePos && (
                <>
                    {/* Outline */}
                    <motion.svg
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        preserveAspectRatio="none"
                        className="absolute left-[-75px] w-[375px] h-full"
                        width="374"
                        height="102"
                        viewBox="0 0 374 102"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <motion.path
                            // animate={animationControls}
                            d="M373 1H51L1.5 51L51 101H373V1Z"
                            fill="white"
                            stroke="#A804EA"
                        />
                    </motion.svg>
                    {/* Inner Border */}

                    {/* Big arrow */}
                    <motion.svg
                        initial={{ x: -60, scaleX: 1.05, opacity: 0 }}
                        animate={{ x: -60, scaleX: 1.05, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="absolute left-0 "
                        width="34"
                        height="66"
                        viewBox="0 0 34 66"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M33 1L1 33L33 65" stroke="#A804EA" />
                    </motion.svg>
                    {/* Small Arrow */}
                    <motion.svg
                        initial={{ x: -70, scaleX: 0, opacity: 0 }}
                        animate={{ x: -90, scaleX: 1, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="absolute left-0"
                        width="14"
                        height="26"
                        viewBox="0 0 14 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M13 1L1 13L13 25" stroke="#A804EA" />
                    </motion.svg>
                </>
            )}

            {pos !== activePos && <span className="z-10">{text}</span>}
            {pos === activePos && (
                <span className="z-10">
                    <HackerText
                        text={text}
                        speed={350 / text.length}
                    ></HackerText>
                </span>
            )}
        </motion.div>
    );
};

const Carousel = ({
    setActive,
    itemOrder,
    shiftBy,
    activePos,
}: {
    setActive: object;
    itemOrder: [{ key: string; text: string; title: string }];
    shiftBy: any;
    activePos: number;
}) => {
    return (
        <>
            <AnimatePresence>
                {/* Carousel Container */}
                {/* <div className="relative h-[1300px] w-96 gap-5 overflow-y-visible bg-slate-300 pt-[200px] transition-all"> */}
                <div className="relative h-full w-full bg-slate-300 xxl:top-[-450px] xxl:mt-[0px] hidden lg:block lg:top-[-670px]">
                    {itemOrder.map(
                        (
                            {
                                key,
                                title,
                                html,
                            }: { key: string; title: string; html: string },
                            i: number
                        ): any => {
                            // console.log(key);
                            return (
                                <CarouselItem
                                    key={key}
                                    text={title}
                                    pos={i}
                                    isLast={i === itemOrder.length - 1}
                                    isFirst={i === 0}
                                    shiftBy={shiftBy}
                                    activePos={activePos}
                                />
                            );
                        }
                    )}
                </div>
            </AnimatePresence>
        </>
    );
};

export default Carousel;
