import React, {
    ReactElement,
    useState,
    useRef,
    useEffect,
    useCallback,
} from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

// Component imports
import Image from "gatsby-image";

import BackgroundImage from "gatsby-background-image";
import Section from "../components/Section";
import Text from "../components/Text";
import FeaturedPost from "../components/FeaturedPost";
import CategoryFilter from "../components/CategoryFilter";
import Clipboard from "react-clipboard.js";
import { InView } from "react-intersection-observer";
import SEO from "../components/SEO";
import Statistics from "../components/Home/Statistics";

import Layout from "../components/Layout";

import {
    motion,
    useAnimation,
    AnimatePresence,
    useAnimationControls,
} from "framer-motion";

import championsBG from "../images/champions_background.svg";
import championsBGMobile from "../images/champions_bg_mobile.svg";
import render from "../images/gif.gif";

import tutorial1 from "../images/tutorial_1.svg";
import tutorial2 from "../images/tutorial_2.svg";
import tutorial3 from "../images/tutorial_3.svg";
import Carousel from "../components/Home/ChampCarousel";
const ProgressBar = ({ time }) => {
    // console.log("mounted");
    // console.log(time);
    return (
        <div className="hidden  xxl:top-[450px] w-[390px] relative h-[1px] bg-gray-200 xxl:left-[420px] md:block md:top-[400px] md:left-[335px] md:w-[300px] lg:left-[430px] lg:top-[450px] lg:w-[370px]">
            <motion.div
                key={time}
                initial={{
                    right: "100%",
                }}
                animate={{
                    right: "0",
                }}
                transition={{
                    ease: "linear",
                    duration: 5,
                }}
                className="absolute inset-0 bg-[#A804EA]"
            ></motion.div>
        </div>
    );
};

function Index(): ReactElement {
    const [copiedIp, setCopiedIp] = useState(false);

    // Refs and State
    const mobileAbilities = useRef(null);
    const [mobileAbilitiesWidth, setMobileAbilitiesWidth] = useState(0);

    const desktopAbilities = useRef(null);
    const [desktopAbilitiesHeight, setDesktopAbilitiesHeight] = useState(0);
    const desktopAbilitiesControl = useAnimation();

    const data = useStaticQuery(graphql`
        {
            home_back: file(relativePath: { eq: "home_back_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            home_middle: file(relativePath: { eq: "home_middle_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            home_front: file(relativePath: { eq: "home_front_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            home_mobile: file(relativePath: { eq: "home_mobile.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            logo: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            featuredArticles: allGhostPost(
                filter: { tags: { elemMatch: { name: { eq: "featured" } } } }
                limit: 2
                sort: { fields: published_at, order: DESC }
            ) {
                edges {
                    node {
                        title
                        excerpt
                        slug
                        feature_image
                        tags {
                            name
                        }
                    }
                }
            }
            bigFeature: allGhostPost(
                filter: { tags: { elemMatch: { name: { eq: "bigFeature" } } } }
            ) {
                edges {
                    node {
                        title
                        excerpt
                        slug
                        feature_image
                        tags {
                            name
                        }
                    }
                }
            }

            champions: allGhostPost(
                filter: { tags: { elemMatch: { name: { eq: "champion" } } } }
            ) {
                edges {
                    node {
                        title
                        html
                    }
                }
            }
        }
    `);

    let abilities: [{ title: string; html: string }] = [];
    data.champions.edges.map(({ node }: any, i: number) =>
        abilities.push(node)
    );
    const [activeAbility, setActiveAbility] = useState({
        title: abilities[0].title,
        html: abilities[0].html,
    });

    const setActive = (title: string, html: string) => {
        setActiveAbility({ title: title, html: html });
    };

    const shiftBy = (num: number) => {
        setTime(Date.now());
        // Shift up
        if (num < 0) {
            const arrayCopy = [...itemOrder];
            for (let i = 0; i < Math.abs(num); i++) {
                const firstItem = arrayCopy.shift();

                arrayCopy.push(firstItem);
            }
            setItemOrder((prevState) => {
                return arrayCopy;
            });
        }
        // Shift down
        if (num > 0) {
            const arrayCopy = [...itemOrder];

            for (let i = 0; i < num; i++) {
                const lastItem = arrayCopy.pop();

                arrayCopy.unshift(lastItem);
            }
            setItemOrder((prevState) => {
                return arrayCopy;
            });
        }
    };
    const activePos = 10;
    const [time, setTime] = useState(Date.now());

    useCallback(() => {
        shiftBy(-1);
    }, [shiftBy]);

    useEffect(() => {
        const interval = setInterval(() => {
            shiftBy(-1);
        }, 5000);
        return () => clearInterval(interval);
    }, [shiftBy]);
    const items1 = abilities.map((ability: any) => ({
        title: ability.title,
        key: ability.title,
        html: ability.html,
    }));

    const items2 = abilities.map((ability: any) => ({
        title: ability.title,
        key: ability.title + "2",
        html: ability.html,
    }));
    const items3 = abilities.map((ability: any) => ({
        title: ability.title,
        key: ability.title + "3",
        html: ability.html,
    }));
    const itemArr = [...items1, ...items2, ...items3];
    const [itemOrder, setItemOrder] = useState(itemArr);

    useEffect(() => {
        setActive(itemOrder[activePos].title, itemOrder[activePos].html);
    }, [itemOrder]);

    return (
        <Layout>
            <SEO title="Home" />
            {/* Mobile Header */}

            <BackgroundImage
                className="lg:hidden h-screen bg-bottom"
                fluid={data.home_mobile.childImageSharp.fluid}
                style={{ backgroundSize: "100% 50%" }}
            >
                <div className="flex flex-col items-center justify-center h-full transform -translate-y-16">
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                            stiffness: 400,
                            damping: 30,
                            mass: 2.5,
                            type: "spring",
                        }}
                    >
                        <Image
                            fluid={data.logo.childImageSharp.fluid}
                            alt="SpaceDelta Logo"
                            className="w-64 mb-10"
                        />
                    </motion.div>

                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                            stiffness: 400,
                            damping: 30,
                            mass: 2.5,
                            type: "spring",
                        }}
                    >
                        <a
                            href="#JoinInstructions"
                            className="copyIP hover:bg-purple-400 focus:bg-purple-400 focus:outline-none group ease-easeInOutQuint relative px-6 py-5 font-bold tracking-wide uppercase transition-all duration-500 transform bg-purple-300"
                        >
                            Play Now
                            {/* Border */}
                            <div className="group-hover:scale-95 ease-easeInOutQuint absolute inset-0 -m-3 transition-all duration-500 transform border-2 border-gray-500"></div>
                        </a>
                    </motion.div>
                </div>
            </BackgroundImage>

            {/* Desktop Header */}
            <section className=" lg:flex relative flex-col items-center hidden h-screen max-h-screen overflow-hidden">
                {/* Background images */}
                <motion.div
                    initial={{ opacity: 0, y: "50vh" }}
                    animate={{ opacity: 1, y: -64 }}
                    transition={{
                        delay: 0.5,
                        stiffness: 100,
                        damping: 30,
                        type: "spring",
                    }}
                    className="absolute inset-x-0 bottom-0 w-full transform -translate-y-16"
                >
                    <Image
                        fluid={data.home_back.childImageSharp.fluid}
                        alt=""
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: "50vh" }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.5,
                        stiffness: 100,
                        damping: 30,
                        type: "spring",
                    }}
                    className="absolute inset-x-0 bottom-0 w-full transform"
                >
                    <Image
                        fluid={data.home_middle.childImageSharp.fluid}
                        alt=""
                        draggable={false}
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: "50vh" }}
                    animate={{ opacity: 1, y: 16 }}
                    transition={{
                        delay: 0.5,
                        stiffness: 100,
                        damping: 30,
                        type: "spring",
                    }}
                    className="absolute inset-x-0 bottom-0 w-full transform translate-y-8"
                >
                    <Image
                        fluid={data.home_front.childImageSharp.fluid}
                        alt=""
                        draggable={false}
                    />
                </motion.div>

                <Section className="flex flex-col flex-1">
                    <div className="flex flex-col items-center flex-1">
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{
                                delay: 2,
                                stiffness: 400,
                                damping: 30,
                                mass: 2.5,
                                type: "spring",
                            }}
                            className="lg:w-80 2xl:w-112"
                        >
                            <Image
                                fluid={data.logo.childImageSharp.fluid}
                                alt="SpaceDelta Logo"
                                className="w-full h-full"
                            />
                        </motion.div>

                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{
                                delay: 2,
                                stiffness: 400,
                                damping: 30,
                                mass: 2.5,
                                type: "spring",
                            }}
                        >
                            {/* <Clipboard data-clipboard-text="spacedelta.net"> */}
                            <a
                                href="#JoinInstructions"
                                // onClick={() => {
                                //     setCopiedIp(true);
                                //     setTimeout(() => {
                                //         setCopiedIp(false);
                                //     }, 2000);
                                // }}
                                className="copyIP hover:bg-purple-400 focus:bg-purple-400 focus:outline-none group ease-easeInOutQuint relative px-6 py-5 font-bold tracking-wide uppercase transition-all duration-500 transform -translate-y-12 bg-purple-300"
                            >
                                Play Now
                                {/* {copiedIp ? "COPIED!" : "spacedelta.net"} */}
                                {/* Border */}
                                <div className="group-hover:scale-95 ease-easeInOutQuint absolute inset-0 -m-3 transition-all duration-500 transform border-2 border-gray-500"></div>
                            </a>
                            {/* </Clipboard> */}
                        </motion.div>
                    </div>

                    {/* Featured news */}
                    {data.featuredArticles && (
                        <div className="homeFeaturedArticles flex flex-col items-end justify-end transform -translate-y-8">
                            <Section verticalPadding={false}>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 2.5 }}
                                >
                                    <Text
                                        variant="p"
                                        className="xxl:transform xxl:w-40 xxl:-rotate-90 xxl:-translate-x-32 xxl:translate-y-16 xxl:mb-0 md:mb-8 mb-8 text-xs font-semibold uppercase"
                                    >
                                        - Featured News -
                                    </Text>
                                </motion.div>
                                <div className="lg:grid-cols-3 grid gap-12">
                                    <InView triggerOnce threshold={0.2}>
                                        {({ inView, ref }) => (
                                            <div ref={ref}>
                                                <motion.div
                                                    initial={{
                                                        y: 50,
                                                        opacity: 0,
                                                    }}
                                                    animate={
                                                        inView && {
                                                            y: 0,
                                                            opacity: 1,
                                                        }
                                                    }
                                                    transition={{ delay: 2.5 }}
                                                >
                                                    {console.log(
                                                        data.bigFeature
                                                    )}
                                                    <FeaturedPost
                                                        desc={
                                                            data.bigFeature
                                                                .edges[0].node
                                                                .excerpt
                                                        }
                                                        url={
                                                            data.bigFeature
                                                                .edges[0].node
                                                                .slug
                                                        }
                                                        title={
                                                            data.bigFeature
                                                                .edges[0].node
                                                                .title
                                                        }
                                                        img={
                                                            data.bigFeature
                                                                .edges[0].node
                                                                .feature_image
                                                        }
                                                        category={
                                                            data.bigFeature
                                                                .edges[0].node
                                                                .tags[1]
                                                                .name ===
                                                            "gameUpdates"
                                                                ? "game updates"
                                                                : data
                                                                      .bigFeature
                                                                      .edges[0]
                                                                      .node
                                                                      .tags[1]
                                                                      .name
                                                        }
                                                    />
                                                </motion.div>
                                            </div>
                                        )}
                                    </InView>
                                    {data.featuredArticles &&
                                        data.featuredArticles.edges.map(
                                            (
                                                featuredArticle: any,
                                                i: number
                                            ) => (
                                                <InView
                                                    triggerOnce
                                                    threshold={0.2}
                                                    key={i}
                                                >
                                                    {({ inView, ref }) => (
                                                        <div ref={ref}>
                                                            <motion.div
                                                                initial={{
                                                                    y: 50,
                                                                    opacity: 0,
                                                                }}
                                                                animate={
                                                                    inView && {
                                                                        y: 0,
                                                                        opacity: 1,
                                                                    }
                                                                }
                                                                transition={{
                                                                    delay:
                                                                        i *
                                                                            0.25 +
                                                                        2.75,
                                                                }}
                                                            >
                                                                <FeaturedPost
                                                                    category={
                                                                        featuredArticle
                                                                            .node
                                                                            .tags[0]
                                                                            .name ===
                                                                        "gameUpdates"
                                                                            ? "game updates"
                                                                            : featuredArticle
                                                                                  .node
                                                                                  .tags[1]
                                                                                  .name
                                                                    }
                                                                    desc={
                                                                        featuredArticle
                                                                            .node
                                                                            .excerpt
                                                                    }
                                                                    url={
                                                                        featuredArticle
                                                                            .node
                                                                            .slug
                                                                    }
                                                                    title={
                                                                        featuredArticle
                                                                            .node
                                                                            .title
                                                                    }
                                                                    img={
                                                                        featuredArticle
                                                                            .node
                                                                            .feature_image
                                                                    }
                                                                />
                                                            </motion.div>
                                                        </div>
                                                    )}
                                                </InView>
                                            )
                                        )}
                                </div>
                                <motion.div
                                    className="flex justify-end mt-5"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 3.5 }}
                                >
                                    <Link
                                        className="focus:outline-none focus:text-purple-600 hover:text-purple-600 ease-easeInOutQuint group flex items-center justify-end text-xs font-semibold uppercase transition-all duration-300"
                                        to="/news"
                                    >
                                        <span>View All</span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="8.707"
                                            viewBox="0 0 16 8.707"
                                            className={`ml-2 transition-all duration-300 group-hover:translate-x-2 ease-easeInOutQuint transform`}
                                        >
                                            <g
                                                id="Group_35"
                                                data-name="Group 35"
                                                transform="translate(-1716.5 -2177.146)"
                                            >
                                                <line
                                                    id="Line_3"
                                                    data-name="Line 3"
                                                    x2="11"
                                                    transform="translate(1721.5 2181.5)"
                                                    fill="none"
                                                    stroke="#d86aff"
                                                    stroke-width="1"
                                                />
                                                <line
                                                    id="Line_6"
                                                    data-name="Line 6"
                                                    x2="1"
                                                    transform="translate(1716.5 2181.5)"
                                                    fill="none"
                                                    stroke="#d86aff"
                                                    stroke-width="1"
                                                />
                                                <line
                                                    id="Line_4"
                                                    data-name="Line 4"
                                                    x2="4"
                                                    y2="4"
                                                    transform="translate(1727.5 2177.5)"
                                                    fill="none"
                                                    stroke="#d86aff"
                                                    stroke-width="1"
                                                />
                                                <line
                                                    id="Line_5"
                                                    data-name="Line 5"
                                                    y1="4"
                                                    x2="4"
                                                    transform="translate(1727.5 2181.5)"
                                                    fill="none"
                                                    stroke="#d86aff"
                                                    stroke-width="1"
                                                />
                                            </g>
                                        </svg>
                                    </Link>
                                </motion.div>
                            </Section>
                        </div>
                    )}
                </Section>
            </section>

            {/* Mobile Featured Section */}
            {data.featuredArticles && (
                <div className="lg:hidden homeFeaturedMobileArticles bg-purple-900">
                    <Section>
                        <Text
                            variant="p"
                            className="mb-8 text-xs font-semibold uppercase"
                        >
                            - Featured News -
                        </Text>
                        <div className="lg:grid-cols-3 grid gap-12">
                            <InView triggerOnce threshold={0.2}>
                                {({ inView, ref }) => (
                                    <div ref={ref}>
                                        <motion.div
                                            initial={{ y: 50, opacity: 0 }}
                                            animate={
                                                inView && { y: 0, opacity: 1 }
                                            }
                                        >
                                            <FeaturedPost
                                                desc={
                                                    data.bigFeature.edges[0]
                                                        .node.excerpt
                                                }
                                                url={
                                                    data.bigFeature.edges[0]
                                                        .node.slug
                                                }
                                                title={
                                                    data.bigFeature.edges[0]
                                                        .node.title
                                                }
                                                img={
                                                    data.bigFeature.edges[0]
                                                        .node.feature_image
                                                }
                                            />
                                        </motion.div>
                                    </div>
                                )}
                            </InView>
                            {data.featuredArticles.edges.map(
                                (featuredArticle: any, i: number) => (
                                    <InView triggerOnce threshold={0.2} key={i}>
                                        {({ inView, ref }) => (
                                            <div ref={ref}>
                                                <motion.div
                                                    initial={{
                                                        y: 50,
                                                        opacity: 0,
                                                    }}
                                                    animate={
                                                        inView && {
                                                            y: 0,
                                                            opacity: 1,
                                                        }
                                                    }
                                                >
                                                    <FeaturedPost
                                                        desc={
                                                            featuredArticle.node
                                                                .excerpt
                                                        }
                                                        url={
                                                            featuredArticle.node
                                                                .slug
                                                        }
                                                        title={
                                                            featuredArticle.node
                                                                .title
                                                        }
                                                        img={
                                                            featuredArticle.node
                                                                .feature_image
                                                        }
                                                    />
                                                </motion.div>
                                            </div>
                                        )}
                                    </InView>
                                )
                            )}
                        </div>
                    </Section>
                </div>
            )}

            {/* Champions Section */}
            <div className="text-black bg-gray-300">
                <Section>
                    <div className="lg:grid lg:grid-cols-4">
                        <div className="lg:col-span-3">
                            <div>
                                <Text
                                    variant="p"
                                    className="lg:text-sm xxl:transform xxl:w-40 xxl:-rotate-90 xxl:-translate-x-32 xxl:translate-y-8 xxl:mb-0 md:mb-8 mb-8 text-xs font-semibold uppercase"
                                >
                                    - Champions -
                                </Text>
                                <Text
                                    variant="p"
                                    className="lg:w-1/2 lg:-mt-5 mb-10 font-light tracking-wide"
                                >
                                    Dive right into SpaceDelta's fantastic
                                    universe. Explore the land, find hundreds of
                                    runes, and build your perfect character!
                                </Text>
                                <Text
                                    variant="h2"
                                    className="xxl:w-1/2 xxl:text-6xl xxl:leading-snug text-black"
                                >
                                    Build your abilities, human
                                </Text>
                                <a
                                    className="xxl:w-64 hover:bg-black focus:outline-none focus:bg-black hover:text-white focus:text-white ease-easeInOutQuint w-48 py-3 mt-5 font-semibold uppercase transition-all duration-300 border-2 border-black block text-center"
                                    href="#JoinInstructions"
                                >
                                    Play now
                                </a>
                                {/* <Clipboard data-clipboard-text="spacedelta.net">
                                    <button
                                        onClick={() => {
                                            setCopiedIp(true);
                                            setTimeout(() => {
                                                setCopiedIp(false);
                                            }, 2000);
                                        }}
                                        className="xxl:w-64 hover:bg-black focus:outline-none focus:bg-black hover:text-white focus:text-white ease-easeInOutQuint w-48 py-3 mt-5 font-semibold uppercase transition-all duration-300 border-2 border-black"
                                    >
                                        {copiedIp ? "IP COPIED!" : "Play now"}
                                    </button>
                                </Clipboard> */}
                            </div>

                            {/* Mobile Abilities */}

                            <div className="lg:hidden my-12">
                                {/* <Text
                                    variant="p"
                                    className="mb-4 text-xs font-semibold uppercase"
                                >
                                    - Abilities -
                                </Text> */}

                                <div
                                    className="overflow-hidden"
                                    ref={mobileAbilities}
                                ></div>
                            </div>

                            {/* Champion */}
                            <div>
                                <div className="relative">
                                    {/* Background Image */}
                                    {/* <img
                                        src={championsBG}
                                        alt=""
                                        className="lg:block absolute inset-0 hidden max-w-full max-h-full"
                                    /> */}
                                    {/* <svg
                                        className="lg:block absolute inset-0 hidden max-w-full max-h-full pr-5"
                                        width="918"
                                        height="573"
                                        viewBox="0 0 918 573"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="0.998551"
                                            y1="288.5"
                                            x2="345.999"
                                            y2="287.5"
                                            stroke="#787878"
                                        />
                                        <path
                                            d="M49.5 287.5C49.5 300.193 38.9887 310.5 26 310.5C13.0113 310.5 2.5 300.193 2.5 287.5C2.5 274.807 13.0113 264.5 26 264.5C38.9887 264.5 49.5 274.807 49.5 287.5Z"
                                            stroke="#787878"
                                        />
                                        <circle
                                            cx="631.5"
                                            cy="286.5"
                                            r="285.5"
                                            stroke="#A804EA"
                                        />
                                    </svg> */}
                                    <svg
                                        className="lg:block absolute right-0 top-0 bottom-0 hidden max-w-full max-h-full pr-5"
                                        width="918"
                                        height="573"
                                        viewBox="0 0 918 573"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="0.998551"
                                            y1="288.5"
                                            x2="345.999"
                                            y2="287.5"
                                            stroke="#787878"
                                        />
                                        <path
                                            d="M49.5 287.5C49.5 300.193 38.9887 310.5 26 310.5C13.0113 310.5 2.5 300.193 2.5 287.5C2.5 274.807 13.0113 264.5 26 264.5C38.9887 264.5 49.5 274.807 49.5 287.5Z"
                                            stroke="#787878"
                                        />
                                        <circle
                                            cx="631.5"
                                            cy="286.5"
                                            r="285.5"
                                            stroke="#A804EA"
                                        />
                                        <path
                                            d="M632.368 555C543.586 555 464.868 511.91 415.987 445.5H848.749C799.868 511.91 721.15 555 632.368 555Z"
                                            fill="white"
                                            stroke="#A804EA"
                                        />
                                    </svg>

                                    {/* Ability Info */}
                                    <div className="lg:block absolute hidden left-[360px] top-[405px] text-center text-xs w-[241px] xl:top-[460px] xl:left-[485px] xxl:top-[460px] xxl:left-[490px] ">
                                        <span className="uppercase text-[#A804EA]">
                                            {activeAbility.title}
                                        </span>
                                        <div
                                            className=" "
                                            dangerouslySetInnerHTML={{
                                                __html: activeAbility.html,
                                            }}
                                        ></div>
                                    </div>
                                    <ProgressBar time={time}></ProgressBar>

                                    <img
                                        src={championsBGMobile}
                                        alt=""
                                        className="lg:hidden absolute inset-0 w-full max-h-full"
                                    />
                                    <img
                                        src={render}
                                        alt={"Build your champion"}
                                        className="lg:transform lg:translate-x-32 lg:-translate-y-16 xxl:translate-x-40 xxl:-translate-y-24 lg:scale-60 lg:ml-auto relative max-w-full max-h-full xl:-translate-y-28 xl:translate-x-36"
                                    />
                                </div>
                                <div>
                                    <p
                                        className={`tracking-wide font-light mt-10  lg:w-1/2`}
                                    >
                                        Natives of the Earth colony, the Terran
                                        are known across the universe for their
                                        ability to thrive in tough situations
                                        and pack-bond with even the ill-natured
                                        species. Runes derived from Terran
                                        bloodlines often help users excel in
                                        diplomacy, survivability and luck.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Abilities */}
                        <div>
                            <Text
                                variant="p"
                                className="hidden md:block  lg:text-sm xxl:transform xxl:w-40 xxl:translate-y-2 xxl:-rotate-90 xxl:-translate-x-40 xxl:mb-0 md:mb-8 mb-8 text-xs font-semibold uppercase"
                            >
                                - Abilities -
                            </Text>
                            <div
                                className="h-[1300px] w-[300px]"
                                style={{
                                    clipPath:
                                        "polygon(-125px -10px, calc(100% + 10px) -10px, calc(100% + 10px) calc(100% + 10px), -125px calc(100% + 10px))",
                                }}
                            >
                                <Carousel
                                    setActive={setActive}
                                    shiftBy={shiftBy}
                                    activePos={activePos}
                                    itemOrder={itemOrder}
                                ></Carousel>
                            </div>
                        </div>
                    </div>
                </Section>
                {/* Join Instructions Section */}
                <div className="bg-white " id="JoinInstructions">
                    <Section className="">
                        <div>
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 place-content-center">
                                <article className="grid gap-3 grid-cols-[auto,1fr] border border-black border-solid px-3 justify-center items-center max-w-[440px] m-auto">
                                    <div className="col-start-1 col-end-1 font-extrabold text-8xl pb-5 ml-2">
                                        1
                                    </div>
                                    <div className="col-start-2 col-end-2 mt-2 min-h-[120px] ml-2">
                                        <div className=" font-extrabold text-[22px] uppercase mt-2">
                                            Launch Java Minecraft
                                        </div>
                                        <div className="font-medium text-sm">
                                            Our server is only available to
                                            those with the Java edition of
                                            Minecraft. 
                                        </div>
                                    </div>
                                    {/* Line break */}
                                    <div className="col-span-2 w-full h-[0.5px] bg-gray-400 mx-auto"></div>
                                    <img
                                        className="col-span-2 w-full max-h-[250px] pb-5 m-auto aspect-square"
                                        src={tutorial1}
                                        alt=""
                                    />
                                </article>
                                <article className="grid gap-3 grid-cols-[auto,1fr] border border-black border-solid px-3 justify-center items-center max-w-[440px] m-auto">
                                    <div className="col-start-1 col-end-1 font-extrabold text-8xl mt-2 pb-5 ml-2">
                                        2
                                    </div>
                                    <div className="col-start-2 col-end-2 mt-2 min-h-[120px] ml-2">
                                        <div className="font-extrabold text-[22px] uppercase mt-2  ">
                                            Use the Multiplayer Option
                                        </div>
                                        <div className="font-medium text-sm">
                                            On the next screen, select “Add
                                            Server”
                                        </div>
                                    </div>
                                    {/* Line break */}
                                    <div className="col-span-2 w-full h-[0.5px] bg-gray-400 mx-auto"></div>
                                    <img
                                        className="col-span-2 w-full max-w-[300px] pb-5 m-auto"
                                        src={tutorial2}
                                        alt=""
                                    />
                                </article>
                                <article className="grid gap-3 grid-cols-[auto,1fr] border border-black border-solid px-3 justify-center items-center max-w-[440px] m-auto">
                                    <div className="col-start-1 col-end-1 font-extrabold text-8xl mt-2 pb-5 ml-2">
                                        3
                                    </div>
                                    <div className="col-start-2 col-end-2 mt-2 min-h-[120px] ml-2">
                                        <div className="font-extrabold text-[22px] uppercase mt-2 ">
                                            In the “Server Address” FIELD
                                        </div>

                                        <div className="font-medium text-sm">
                                            Paste our IP:
                                            <Clipboard data-clipboard-text="spacedelta.net">
                                                <span
                                                    className="text-[#C74CE1] font-bold inline-flex ml-1 justify-center items-center"
                                                    onClick={() => {
                                                        setCopiedIp(true);
                                                        setTimeout(() => {
                                                            setCopiedIp(false);
                                                        }, 2000);
                                                    }}
                                                >
                                                    <span>SPACEDELTA.NET</span>
                                                    <svg
                                                        className="ml-1 "
                                                        width="10"
                                                        height="8"
                                                        viewBox="0 0 10 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M7.8125 0.875V2.4375H9.375L7.8125 0.875Z"
                                                            fill="#C74CE1"
                                                        />
                                                        <path
                                                            d="M7.1875 3.0625V0.5625H1.875V5.875H9.6875V3.0625H7.1875Z"
                                                            fill="#C74CE1"
                                                        />
                                                        <path
                                                            d="M1.25 6.5V2.125H0.3125V7.4375H8.125V6.5H1.25Z"
                                                            fill="#C74CE1"
                                                        />
                                                    </svg>
                                                    <span>
                                                        {" "}
                                                        {copiedIp
                                                            ? "COPIED!"
                                                            : "COPY"}
                                                        {/* Border */}
                                                    </span>
                                                </span>
                                            </Clipboard>
                                        </div>
                                    </div>
                                    {/* Line break */}
                                    <div className="col-span-2 w-full h-[0.5px] bg-gray-400 mx-auto"></div>
                                    <img
                                        className="col-span-2 w-full max-w-[300px] pb-5 m-auto aspect-sq"
                                        src={tutorial3}
                                        alt=""
                                    />
                                </article>
                            </div>
                            <article className="flex flex-wrap max-w-[440px] md:max-w-none flex-column gap-x-8 border border-black border-solid px-3 justify-center items-center mt-4 py-4">
                                <div className="font-extrabold text-8xl">4</div>
                                <div className="font-extrabold text-[22px] uppercase w-full flex-1 md:max-w-xs">
                                    IF YOU'RE HAVING TROUBLES LOGGING IN...
                                </div>
                                <div className="font-medium text-sm max-w-xs inline-block">
                                    Join &nbsp;
                                    <a
                                        className="font-bold inline-flex text-[#C74CE1] focus:outline-none focus:text-purple-600 hover:text-purple-600 ease-easeInOutQuint group items-center justify-end text-xs font-semibold uppercase transition-all duration-300"
                                        href="https://discord.com/spacedelta"
                                    >
                                        OUR DISCORD
                                    </a>
                                    &nbsp; and we can help you out personally
                                </div>
                            </article>
                        </div>
                    </Section>
                </div>
                <div className="bg-purple-300">
                    <Section>
                        <Statistics />
                    </Section>
                </div>
            </div>
        </Layout>
    );
}
export default Index;
