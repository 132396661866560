import React, { useState } from "react";
import useSWR from "swr";

// Component imports
import Clipboard from "react-clipboard.js";

// Helpers
const fetcher = (url: string) => fetch(url).then((r) => r.json());

const Statistics = () => {
    // State
    const [copiedIp, setCopiedIp] = useState(false);

    // Helpers
    const { data, error } = useSWR("/.netlify/functions/statistics", fetcher);

    console.log(data, error);

    return (
        <div className="lg:w-10/12 lg:mx-auto xl:w-8/12 lg:grid lg:grid-cols-3 lg:space-y-0 flex flex-col space-y-2">
            <div className="bg-opacity-20 flex items-center justify-center py-5 bg-black">
                <h4 className="opacity-70 text-lg font-semibold text-white uppercase">
                    {/* {!data
                        ? "Loading"
                        : `${data.stat.uniquePlayers} Unique Players`} */}
                    0 Unique Players
                </h4>
            </div>
            <Clipboard data-clipboard-text="spacedelta.net">
                <button
                    onClick={() => {
                        setCopiedIp(true);
                        setTimeout(() => {
                            setCopiedIp(false);
                        }, 2000);
                    }}
                    className="bg-opacity-10 hover:bg-white focus:bg-white focus:outline-none hover:text-purple-300 focus:text-purple-300 flex items-center justify-center w-full py-5 text-white duration-300 ease-in-out bg-black"
                >
                    <h4 className="text-lg font-semibold text-current uppercase">
                        {copiedIp ? "Copied" : "spacedelta.net"}
                    </h4>
                </button>
            </Clipboard>
            <div className="bg-opacity-20 flex items-center justify-center py-5 bg-black">
                <h4 className="opacity-70 text-lg font-semibold text-white uppercase">
                    {/* {!data ? "Loading" : `${data.stat.onlinePlayers} Online Players`} */}
                    0 Online Players
                </h4>
            </div>
        </div>
    );
};

export default Statistics;
